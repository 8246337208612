import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Cookies from 'js-cookie';

import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  AppBar,
  Toolbar,
  CssBaseline,
  Collapse,
  Grid,
  Typography
} from '@mui/material';

import LogoImg from '../assets/logo.png';
import ContactImg from '../assets/menu/contact.png';
import ProductImg from '../assets/menu/product.png';
import LogoutImg from '../assets/menu/logout.png';
import BrandImg from '../assets/menu/brand.png';
import ServiceImg from '../assets/menu/service.png';
import ProjectImg from '../assets/menu/project.png';
import AboutUsImg from '../assets/menu/information.png';
import EventImg from '../assets/menu/event.png';
import NewsImg from '../assets/menu/newspaper.png';
import BannerImg from '../assets/menu/banner.png';
import CodeImg from '../assets/menu/coding.png';
import FacilityImg from '../assets/menu/facility.png';

import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { API } from '../config/API';
// import { setUser, logout } from '../store/actions/user';
import { setUser, logout } from '../store/action';

const drawerWidth = 240;

function Navsidebar(props) {
  const [menuSelected, setMenuSelected] = React.useState('Banner');

  const [product, setProduct] = React.useState(false);
  const [project, setProject] = React.useState(false);

  const menus = [
    {
      value: "Banner",
      sub: [],
      link: '/banner',
      icon: BannerImg,
    },
    {
      value: "Contact",
      sub: [],
      link: "/contact-us",
      icon: ContactImg,
    },
    {
      value: "Brand",
      sub: [],
      link: '/brand',
      icon: BrandImg,
    },
    {
      value: "Product",
      sub: [
        {
          value: "Category Product",
          link: "/product/category",
        },
        {
          value: "List Product",
          link: "/product",
        },
      ],
      expand: true,
      icon: ProductImg,
    },
    {
      value: "Service",
      sub: [],
      link: '/service',
      icon: ServiceImg,
    },
    {
      value: "Project",
      sub: [
        {
          value: "Category Project",
          link: "/project/category",
        },
        {
          value: "List Project",
          link: "/project",
        },
      ],
      expand: true,
      icon: ProjectImg,
    },
    {
      value: "About Us",
      sub: [],
      link: '/about-us',
      icon: AboutUsImg,
    },
    {
      value: "Events",
      sub: [],
      link: '/event',
      icon: EventImg,
    },
    {
      value: "News",
      sub: [],
      link: '/news',
      icon: NewsImg,
    },
    {
      value: "Metatag Main Page",
      sub: [],
      link: '/meta-tag',
      icon: CodeImg,
    },
    {
      value: "Facility",
      sub: [],
      link: '/facility',
      icon: FacilityImg,
    },
  ]

  useEffect(() => {
    async function initFetchData() {
      if (Cookies.get('BPWTKNCMS')) {
        try {
          let { data } = await API.get('/user/check-token', { headers: { token: `Bearer ${Cookies.get('BPWTKNCMS')}` } })
          await props.setUser({
            id: data.id,
            fullname: data.full_name,
            isLogin: true
          })

          if (props.location?.pathname) props.history.push(props.location?.pathname)
          else props.history.push('/contact-us')
        } catch (err) {
          Cookies.remove('BPWTKNCMS')
          // props.history.push('/signin')
        }
      }
    }
    initFetchData()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectMenu = (value) => async () => {
    setMenuSelected(value)

    if (value === "Product") setProduct(!product)
    else if (value === "Project") setProject(!project)
  }

  const handleLogout = () => {
    Cookies.remove('BPWTKNCMS')
    props.history.push('/signin')
  }

  const drawer = (
    <div style={{ marginTop: 60 }}>
      <Box>
        <List>
          {
            menus.map(menu =>
              <div key={menu.value}>
                <ListItem
                  button                  
                  onClick={handleSelectMenu(menu.value)}
                  component={(menu.expand === true) ? null : Link}
                  to={(menu.expand === true) ? null : menu.link}
                  style={{ backgroundColor: menuSelected === menu.value ? '#d9d9d9' : null }}
                >
                  <ListItemIcon>
                    <img src={menu.icon} alt={menu.value} style={{ width: 30 }} />
                  </ListItemIcon>
                  <ListItemText primary={<b>{menu.value}</b>} style={{ color: "#626262" }} />
                  {
                    menu.value === "Product"
                        ? (
                          product
                            ? <ExpandLess />
                            : <ExpandMore />
                        )
                        : menu.value === "Project"
                          ? (
                            project
                              ? <ExpandLess />
                              : <ExpandMore />
                          )
                          : null
                  }
                </ListItem>
                {
                  menu.expand && <Collapse
                    in={
                      menu.value === "Product"
                          ? product
                          : menu.value === "Project"
                            ? project
                            : null
                    }
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {menu.sub.map((submenu) => (
                        <ListItem
                          button
                          key={submenu.value}
                          component={Link}
                          onClick={handleSelectMenu(submenu.value)}
                          to={submenu.link}
                          style={{ backgroundColor: menuSelected === submenu.value ? '#d9d9d9' : null }}
                        >
                          <ListItemText primary={submenu.value} style={{ color: "#626262" }} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                }
              </div>
            )
          }
	 <ListItem
            button
            onClick={handleLogout}
          >
            <ListItemIcon>
              <img src={LogoutImg} alt='logout' style={{ width: 30 }} />
            </ListItemIcon>
            <ListItemText primary={<b>Logout</b>} style={{ color: "#626262" }} />
          </ListItem>
        </List>
      </Box>

    </div>
  )

  if (Cookies.get('BPWTKNCMS')) return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        style={{
          maxWidth: '1920px',
          left: 0,
          height: 65,
          display: 'flex',
          justifyContent: 'center'
        }}
        color="inherit"
      >
        <Toolbar>
          <Grid style={{ width: drawerWidth, display: 'flex', }}>
            <img src={LogoImg} style={{ width: '150px', cursor: 'pointer', marginRight: 10 }} alt="logo-BPW" onClick={() => props.history.push('/signin')} />
            <Typography variant="h5" noWrap component="div" style={{ fontWeight: "bold" }}>
              - CMS
            </Typography>
          </Grid>
          {/* <Typography variant="h5" noWrap component="div">
            {props.fullname}
          </Typography> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        {drawer}
      </Drawer>

    </Box>
  )
  else return <></>
}

Navsidebar.propTypes = {
  window: PropTypes.func,
};

const mapDispatchToProps = {
  setUser,
  logout
}

// const mapStateToProps = ({ user }) => {
//   return {
//     fullname: user.fullname
//   }
// }
const mapStateToProps = ({ fullname }) => {
  return {
    fullname
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navsidebar))
